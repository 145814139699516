import * as R from 'ramda'
import {graphql} from 'gatsby'
import loadable from '@loadable/component'
import React from 'react'

import {locationsPropTypes} from 'helpers/propTypes'
import {removeDuplicateObject} from 'helpers/utils'
import Hero from 'components/UI/Hero'
import Kpis from 'components/AboutUs/Kpis'
import Section from 'components/UI/Section'
import SEO from 'components/seo'
import WorldMap from 'components/UI/WorldMap'

const Locations = ({pageContext, data}) => {
  const countryData = R.path(['contentfulCountry'], data)
  const Decoration = loadable(() => import('components/UI/Decoration'))
  const Decorations = loadable(() => import('components/UI/Decorations'))

  const {
    imageOrVideo,
    metaTitle,
    metaDescription: {metaDescription},
    pageTitle,
    shortDescription: {shortDescription},
    surtitle,
    faxLabel,
    phoneLabel,
    mapTitle,
    kpi,
    mapMarker,
    legend,
  } = R.pathOr(null, ['contentfulLocations'], data)

  const {visitWebsite} =
    process.env.GATSBY_WEBSITE === 'kls'
      ? R.pathOr(null, ['klsLocations', 'edges', 0, 'node'], data)
      : R.pathOr(null, ['keyrusLocations', 'edges', 0, 'node'], data)

  const klsLocations = R.pathOr(
    null,
    ['klsLocations', 'edges', 0, 'node', 'locations'],
    data,
  )
  const keyrusLocations = R.pathOr(
    null,
    ['keyrusLocations', 'edges', 0, 'node', 'locations'],
    data,
  )
  const allLocations = removeDuplicateObject(
    R.concat(klsLocations, keyrusLocations),
  )
  const locationsToRender =
    process.env.GATSBY_WEBSITE === 'kls' ? allLocations : keyrusLocations
  const continents = data.allContentfulContinent.edges
  const siteUrl = R.pathOr(null, ['site', 'siteMetadata', 'siteUrl'], data)
  const countryNameForContactForm = R.pathOr(
    null,
    ['countryNameForContactForm', 'nodes'],
    data,
  )

  const pageMetaData = {
    metaTitle: R.pathOr('', ['metaTitle'], metaTitle) || metaTitle,
    metaDescription:
      R.pathOr('', ['metaDescription'], metaDescription) || metaDescription,
    siteMetaData: R.pathOr('', ['siteMetaData'], data),
    genericData: R.pathOr('', ['contentfulGenericData'], data),
  }

  return (
    <>
      <SEO
        pageContext={pageContext}
        countryData={countryData}
        pageMetaData={pageMetaData}
      />
      <div className="decorationWrapper">
        <Decorations>
          <Decoration webSiteName="keyrus" color="blue" top={52} />
          <Decoration webSiteName="keyrus" color="orange" right={0} top={60} />
          <Decoration
            webSiteName="keyrus"
            color="red"
            right={0}
            bottom={-160}
          />
          <Decoration webSiteName="kls" svg="left" left={-80} top={0} />
          <Decoration webSiteName="kls" svg="right" right={0} top={34} />
        </Decorations>
        <Section hasPaddingTop={false} hasPaddingBottom={false}>
          <Hero
            hat={surtitle}
            title={pageTitle}
            titleType="title"
            description={shortDescription}
            media={imageOrVideo}
          />
        </Section>
      </div>
      <Kpis data={kpi} title={mapTitle} />
      <Section
        hasSmallPadding
        hasPaddingTop={false}
        hasPaddingBottom={false}
        hasPaddingSide={false}
        hasBorderBottom
      >
        <WorldMap
          markers={locationsToRender}
          phone={phoneLabel}
          fax={faxLabel}
          pins={mapMarker}
          continents={continents}
          hasNav
          mapTitle={mapTitle}
          siteUrl={siteUrl}
          visitWebsite={visitWebsite}
          countryNameForContactForm={countryNameForContactForm}
          legend={legend}
        />
      </Section>
    </>
  )
}

Locations.propTypes = locationsPropTypes

Locations.defaultProps = {
  faxLabel: '',
  imageOrVideo: null,
  kpi: null,
  mapMarker: null,
  mapTitle: '',
  metaDescription: null,
  metaTitle: '',
  pageTitle: '',
  phoneLabel: '',
  shortDescription: null,
  surtitle: '',
}

export default Locations

export const pageQuery = graphql`
  query templateLocations(
    $entityRegEx: String
    $nodeLocale: String
    $technicalName: String
  ) {
    contentfulLocations(
      node_locale: {eq: $nodeLocale}
      slug: {regex: $entityRegEx}
    ) {
      ...locationsFields
    }
    allContentfulContinent(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...continentFields
        }
      }
    }
    klsLocations: allContentfulLocations(
      filter: {node_locale: {eq: $nodeLocale}, slug: {regex: "/-kls/"}}
    ) {
      edges {
        node {
          visitWebsite
          legend
          locations {
            ...locationFields
          }
        }
      }
    }
    keyrusLocations: allContentfulLocations(
      filter: {
        node_locale: {eq: $nodeLocale}
        slug: {regex: "/^((?!-kls|-km|-kf|-ns).)*$/"}
      }
    ) {
      edges {
        node {
          visitWebsite
          legend
          locations {
            ...locationFields
          }
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    countryNameForContactForm: allContentfulLocation(
      filter: {node_locale: {eq: "en-US"}}
    ) {
      nodes {
        contentful_id
        countryNameForContactForm
      }
    }
    allContentfulService(filter: {node_locale: {eq: $nodeLocale}}) {
      edges {
        node {
          ...serviceFields
        }
      }
    }
    contentfulCountry(
      technicalName: {eq: $technicalName}
      node_locale: {eq: $nodeLocale}
    ) {
      ...countryDataFields
    }
    siteMetaData: site {
      ...SiteMetadata
    }
    contentfulGenericData {
      genericKeywords {
        content
      }
    }
  }
`
